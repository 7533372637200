// Init Photoswipe.js
// es6

const smallScreenPadding = {
  top: 8,
  bottom: 8,
  left: 8,
  right: 8,
};

const largeScreenPadding = {
  top: 48,
  bottom: 48,
  left: 48,
  right: 48,
};

const chartConfig = {
  gallery: '.chart a',
  mainClass: 'pswp--gallery-light',
  pswpModule: window.VAHURA.photoswipe.module,
  paddingFn: (viewportSize) => {
    return viewportSize.x < 600 ? smallScreenPadding : largeScreenPadding;
  },
  bgOpacity: 1,
};

const galleryConfig = {
  gallery: '.gallery',
  children: 'a',
  mainClass: 'pswp--gallery-dark',
  pswpModule: window.VAHURA.photoswipe.module,
  paddingFn: (viewportSize) => {
    return viewportSize.x < 600 ? smallScreenPadding : largeScreenPadding;
  },
  bgOpacity: 0.95,
};

let lightbox;
let instances = {
  gallery: null,
  chart: null,
};

function getLightbox() {
  if (!lightbox) {
    lightbox = import(window.VAHURA.photoswipe.lightbox).then(
      (module) => module.default,
    );
  }
  return lightbox;
}

function initPhotoswipe(options, instanceKey) {
  var gallery = document.querySelector(options.gallery);
  if (gallery) {
    getLightbox()
      .then((lightbox) => {
        if (instances[instanceKey]) {
          instances[instanceKey].destroy();
        }
        instances[instanceKey] = new lightbox(options);
        return instances[instanceKey];
      })
      .then(addCaptions)
      .then((instance) => {
        instance.init();
      });
  }
}

function addCaptions(lightbox) {
  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector(
              '.photoswipe-caption',
            );
            if (hiddenCaption) {
              // get caption from element with class photoswipe-caption
              captionHTML = hiddenCaption.innerHTML;
            }
          }
          el.innerHTML = captionHTML || '';
          if (el.innerHTML == '') {
            el.style.display = 'none';
          } else {
            el.style.display = '';
          }
        });
      },
    });
  });
  return lightbox;
}

function initAllGalleries() {
  initPhotoswipe(galleryConfig, 'gallery');
  initPhotoswipe(chartConfig, 'chart');
}

initAllGalleries();
document.addEventListener('pjax:success', initAllGalleries);
